require('../css/login.scss');
const $ = require('jquery');
global.$ = global.jQuery = $; //  Pour que jquery soit dispo partout
require('bootstrap');

const routes = require('../../public/js/fos_js_routes.json');
import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
Routing.setRoutingData(routes);

$(function() {

  //  reset pwd
  $("#btn-reset-pwd").click(function(e) {
    e.preventDefault;
    $('#modal-reset-pwd').modal({backdrop: 'static', keyboard: false})
    $('#modal-reset-pwd').modal('show');
  });

  $("#btn-reset-pwd-close").click(function(e) {
    console.log('close');
    e.preventDefault;
    $('#modal-reset-pwd').modal('hide');
  });

  $("#btn-reset-pwd-submit").click(function(e) {
    e.preventDefault;
    $('#btn-reset-pwd-submit' )
      .html("Envoi de la demande&nbsp; <i class='fas fa-spinner fa-spin'></i>")
      .prop("disabled", true);
    var resetPwdUrl = Routing.generate('resetPwd');
    var formDatas = $("#reset-pwd-form").serialize();
    $.post( resetPwdUrl, formDatas)
      .always(function( data ) {
        if ( data.status == 'success') {
          $('#login-message')
          .html('<span class="text-success small">' + data.message + '</span>')
          .show().delay(10000).fadeOut(5000);
        }
        else {
          $('#login-message')
          .html('<span class="text-danger small">' +  data.message + '</span>')
          .show().delay(10000).fadeOut(5000);
        }
        $('#modal-reset-pwd').modal('hide');
        $('#btn-reset-pwd-submit' ).html("Valider").prop("disabled", false);
      });
  });

  $('#login-message').show().delay(10000).fadeOut(5000);

  //  Empêche la soumission du formulaire avec la touche "Entrée"
  $(window).keydown(function(event){
    if(event.keyCode == 13) {
      event.preventDefault();
      return false;
    }
  });


});
